.gradient-background {
    background-image: url("data:image/svg+xml,%3Csvg%20fill%3D%22%235955F5%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cg%20filter%3D%22url(%23filter0_f_2171_20213)%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M282.44%20135.095C317.843%20130.317%20353.394%20122.23%20386.993%20134.351C429.247%20149.595%20484.022%20167.431%20493.542%20211.252C503.198%20255.699%20441.135%20283.883%20429.003%20327.719C415.323%20377.149%20456.452%20441.529%20419.852%20477.519C384.605%20512.178%20324.281%20484.038%20275.045%20479.11C230.253%20474.627%20174.092%20485.688%20146.531%20450.164C118.236%20413.695%20164.654%20358.163%20147.866%20315.19C128.306%20265.12%2029.9558%20245.677%2046.6931%20194.601C63.2696%20144.016%20147.104%20172.795%20198.804%20159.745C227.693%20152.454%20252.91%20139.08%20282.44%20135.095Z%22%20fill%3D%22%235955F5%22%20fill-opacity%3D%220.2%22%2F%3E%0A%3C%2Fg%3E%0A%3Cdefs%3E%0A%3Cfilter%20id%3D%22filter0_f_2171_20213%22%20filterUnits%3D%22userSpaceOnUse%22%20color-interpolation-filters%3D%22sRGB%22%3E%0A%3CfeFlood%20flood-opacity%3D%220%22%20result%3D%22BackgroundImageFix%22%2F%3E%0A%3CfeBlend%20mode%3D%22normal%22%20in%3D%22SourceGraphic%22%20in2%3D%22BackgroundImageFix%22%20result%3D%22shape%22%2F%3E%0A%3CfeGaussianBlur%20stdDeviation%3D%2264%22%20result%3D%22effect1_foregroundBlur_2171_20213%22%2F%3E%0A%3C%2Ffilter%3E%0A%3C%2Fdefs%3E%0A%3C%2Fsvg%3E"),
        url("data:image/svg+xml,%3Csvg%20fill%3D%22%237CAAEF%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cg%20filter%3D%22url(%23filter0_f_2171_20085)%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M164.037%20229.556C189.557%20267.259%20236.83%20293.661%20239.908%20339.089C243.153%20386.999%20217.384%20436.027%20180.072%20466.284C144.113%20495.444%2093.7028%20499.765%2047.6586%20494.989C8.80753%20490.959%20-23.2562%20466.585%20-53.6297%20442.042C-78.526%20421.925%20-89.6089%20391.886%20-110.23%20367.409C-140.093%20331.962%20-196.655%20312.951%20-201.241%20266.828C-205.804%20220.943%20-169.918%20176.999%20-131.738%20151.112C-96.2428%20127.045%20-50.0516%20137.722%20-7.23866%20135.289C30.5901%20133.138%2070.4325%20119.468%20103.619%20137.733C136.527%20155.845%20142.985%20198.452%20164.037%20229.556Z%22%20fill%3D%22%237CAAEF%22%20fill-opacity%3D%220.2%22%2F%3E%0A%3C%2Fg%3E%0A%3Cdefs%3E%0A%3Cfilter%20id%3D%22filter0_f_2171_20085%22%20filterUnits%3D%22userSpaceOnUse%22%20color-interpolation-filters%3D%22sRGB%22%3E%0A%3CfeFlood%20flood-opacity%3D%220%22%20result%3D%22BackgroundImageFix%22%2F%3E%0A%3CfeBlend%20mode%3D%22normal%22%20in%3D%22SourceGraphic%22%20in2%3D%22BackgroundImageFix%22%20result%3D%22shape%22%2F%3E%0A%3CfeGaussianBlur%20stdDeviation%3D%2264%22%20result%3D%22effect1_foregroundBlur_2171_20085%22%2F%3E%0A%3C%2Ffilter%3E%0A%3C%2Fdefs%3E%0A%3C%2Fsvg%3E%0A");
    background-repeat: no-repeat;
    background-position: 0px 190px, -90px 0px;
}

@media (min-width: 1280px) {
    .gradient-background {
        background-position: 10vw 70vh, -2vw 60vh;
    }
}

.dialog-content {
    box-shadow: 0 -3px 6px 0 rgba(99, 88, 69, 0.14),
        0 -10px 10px 0 rgba(99, 88, 69, 0.12),
        0 -23px 14px 0 rgba(99, 88, 69, 0.16),
        0 -41px 16px 0 rgba(99, 88, 69, 0.02),
        0 -64px 18px 0 rgba(99, 88, 69, 0);
}

.user-message {
    min-width: 48px;
    max-width: 70vw;
}

[data-rsbs-backdrop] {
    background: none;
}

[data-rsbs-overlay]::after {
    position: absolute;
    z-index: -1;
    bottom: -70dvh;
    width: 100dvw;
    height: 100dvh;
    content: " ";
    background-image: url("data:image/svg+xml,%3Csvg%20fill%3D%22%235955F5%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cg%20filter%3D%22url(%23filter0_f_2171_20213)%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M282.44%20135.095C317.843%20130.317%20353.394%20122.23%20386.993%20134.351C429.247%20149.595%20484.022%20167.431%20493.542%20211.252C503.198%20255.699%20441.135%20283.883%20429.003%20327.719C415.323%20377.149%20456.452%20441.529%20419.852%20477.519C384.605%20512.178%20324.281%20484.038%20275.045%20479.11C230.253%20474.627%20174.092%20485.688%20146.531%20450.164C118.236%20413.695%20164.654%20358.163%20147.866%20315.19C128.306%20265.12%2029.9558%20245.677%2046.6931%20194.601C63.2696%20144.016%20147.104%20172.795%20198.804%20159.745C227.693%20152.454%20252.91%20139.08%20282.44%20135.095Z%22%20fill%3D%22%235955F5%22%20fill-opacity%3D%220.2%22%2F%3E%0A%3C%2Fg%3E%0A%3Cdefs%3E%0A%3Cfilter%20id%3D%22filter0_f_2171_20213%22%20filterUnits%3D%22userSpaceOnUse%22%20color-interpolation-filters%3D%22sRGB%22%3E%0A%3CfeFlood%20flood-opacity%3D%220%22%20result%3D%22BackgroundImageFix%22%2F%3E%0A%3CfeBlend%20mode%3D%22normal%22%20in%3D%22SourceGraphic%22%20in2%3D%22BackgroundImageFix%22%20result%3D%22shape%22%2F%3E%0A%3CfeGaussianBlur%20stdDeviation%3D%2264%22%20result%3D%22effect1_foregroundBlur_2171_20213%22%2F%3E%0A%3C%2Ffilter%3E%0A%3C%2Fdefs%3E%0A%3C%2Fsvg%3E"),
        url("data:image/svg+xml,%3Csvg%20fill%3D%22%237CAAEF%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cg%20filter%3D%22url(%23filter0_f_2171_20085)%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M164.037%20229.556C189.557%20267.259%20236.83%20293.661%20239.908%20339.089C243.153%20386.999%20217.384%20436.027%20180.072%20466.284C144.113%20495.444%2093.7028%20499.765%2047.6586%20494.989C8.80753%20490.959%20-23.2562%20466.585%20-53.6297%20442.042C-78.526%20421.925%20-89.6089%20391.886%20-110.23%20367.409C-140.093%20331.962%20-196.655%20312.951%20-201.241%20266.828C-205.804%20220.943%20-169.918%20176.999%20-131.738%20151.112C-96.2428%20127.045%20-50.0516%20137.722%20-7.23866%20135.289C30.5901%20133.138%2070.4325%20119.468%20103.619%20137.733C136.527%20155.845%20142.985%20198.452%20164.037%20229.556Z%22%20fill%3D%22%237CAAEF%22%20fill-opacity%3D%220.2%22%2F%3E%0A%3C%2Fg%3E%0A%3Cdefs%3E%0A%3Cfilter%20id%3D%22filter0_f_2171_20085%22%20filterUnits%3D%22userSpaceOnUse%22%20color-interpolation-filters%3D%22sRGB%22%3E%0A%3CfeFlood%20flood-opacity%3D%220%22%20result%3D%22BackgroundImageFix%22%2F%3E%0A%3CfeBlend%20mode%3D%22normal%22%20in%3D%22SourceGraphic%22%20in2%3D%22BackgroundImageFix%22%20result%3D%22shape%22%2F%3E%0A%3CfeGaussianBlur%20stdDeviation%3D%2264%22%20result%3D%22effect1_foregroundBlur_2171_20085%22%2F%3E%0A%3C%2Ffilter%3E%0A%3C%2Fdefs%3E%0A%3C%2Fsvg%3E%0A");
    background-repeat: no-repeat;
    background-position: 0px 190px, -90px 0px;
    background-position: bottom;
}

[data-rsbs-header]::before {
    content: none;
}

[data-rsbs-header]::after {
    position: absolute;
    top: 44px;
    left: 0px;
    width: 100dvw;
    height: 44px;
    content: "";
    background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0.96) 40%,
        rgba(255, 255, 255, 0) 100%
    );
}

[data-rsbs-scroll] {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

[data-rsbs-footer] {
    box-shadow: none;
}

.assistant {
    ol, ul, menu {
        list-style: auto;
        margin: auto;
        padding: revert;
    }

    p {
        padding-bottom: 6px;
    }
}

.ul li {
    list-style-type: disc;
}

ol + .ul li {
    margin-left: 1.5rem;
}
