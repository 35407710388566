/* Dialogue animations */
@keyframes dialogOpen {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes dialogClose {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(100%);
    }
}

.dialog-content {
    animation-duration: 300ms important!;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform, opacity;
}

.dialog-content[data-state="open"] {
    animation-name: dialogOpen;
}

.dialog-content[data-state="closed"] {
    animation-name: dialogClose;
}

@keyframes overlayShow {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes overlayHide {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.dialog-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    inset: 0;
    animation-duration: 300ms;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    will-change: opacity;
}

.dialog-overlay[data-state="open"] {
    animation-name: overlayShow;
}

.dialog-overlay[data-state="closed"] {
    animation-name: overlayHide;
}

/* Wait response animation */
.load-bounce .line-bounce {
    animation: loadingAnimation 0.7s infinite;
}

.load-bounce .line-bounce:nth-child(2) {
    animation-delay: 0.1s;
}

.load-bounce .line-bounce:nth-child(3) {
    animation-delay: 0.2s;
}

.line-bounce {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #ffb534;
}

@keyframes loadingAnimation {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(8px);
    }
}

.background-animation {
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.feedback-content {
    animation-duration: 300ms;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform, opacity;
}

.feedback-content[data-state="open"] {
    animation-name: dialogOpen;
}

.feedback-content[data-state="closed"] {
    animation-name: dialogClose;
}

.animate__animated.animate__delay {
    animation-delay: var(--animate-delay);
}
