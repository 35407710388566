@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "styles/animations.css";

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@layer base {
    @font-face {
        font-family: "Manrope", sans-serif;
        font-style: normal;
        font-display: swap;
        src: local("Manrope"), url(./fonts/Manrope-Bold.ttf),
            url(./fonts/Manrope-ExtraBold.ttf),
            url(./fonts/Manrope-ExtraLight.ttf), url(./fonts/Manrope-Light.ttf),
            url(./fonts/Manrope-Medium.ttf), url(./fonts/Manrope-Regular.ttf),
            url(./fonts/Manrope-SemiBold.ttf),
            url(./fonts/Manrope-VariableFont_wght.ttf);
    }
}

.h-screen {
    height: 100dvh;
}

.shadow-after::after {
    position: absolute;
    bottom: -44px;
    left: 0px;
    width: 100%;
    height: 44px;
    content: "";
    background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0.96) 40%,
        rgba(255, 255, 255, 0) 100%
    );
}
